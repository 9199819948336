import CloudIcon from "@mui/icons-material/Cloud";
import SecurityIcon from "@mui/icons-material/Security";
import TerminalIcon from "@mui/icons-material/Terminal";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import logo from "../asset/images/logoB.png";
import "../css/tag-nav-bar.css";

function TagNavBar() {
  return (
    <>
      <nav className="navbar">
        <div className="nav-logo">
          <img src={logo} alt="Logo de lightcyber" />
          <p>Lightcyber</p>
        </div>
        <div className="nav-tabs">
          <h2>Catégories</h2>
          <div>
            {/* Onglets de la barre de navigation */}
            <div className="nav-tab">
              <CloudIcon></CloudIcon>
              <p>Cloud</p>
            </div>
            <div className="nav-tab">
              <SecurityIcon></SecurityIcon>
              <p>Cybersécurité</p>
            </div>
            <div className="nav-tab">
              <TerminalIcon></TerminalIcon>
              <p>Développement</p>
            </div>
            <div className="nav-tab">
              <FastfoodIcon></FastfoodIcon>
              <p>Nutrition</p>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default TagNavBar;
