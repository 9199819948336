import { TextField } from "@mui/material";
import { Link } from "react-router-dom";

function PasswordForgotten() {
  return (
    <div
      className="display-flex-center"
      style={{ textAlign: "center", padding: "50px" }}
    >
      <h2>
        Un e-mail de réinitialisation de mot de passe a été envoyé à l'adresse
        que vous avez fournie
      </h2>

      <Link to="/signin" className="basic-button w60 m30">
        Connexion
      </Link>
    </div>
  );
}

export default PasswordForgotten;
