import { TextField } from "@mui/material";
import { Link } from "react-router-dom";

function PasswordForgotten() {
  return (
    <div
      className="display-flex-center"
      style={{ textAlign: "center", padding: "50px" }}
    >
      <h2>Vous avez oublié votre mot de passe ?</h2>
      <p className="m30">Veuillez entrer votre adresse e-mail.</p>
      <p>
        Un lien permettant de créer un nouveau mot de passe vous sera envoyé par
        e-mail.
      </p>

      <TextField
        type="email"
        className="auth-input m30 input-center"
        id="email"
        label="Adresse mail"
        variant="standard"
        required
      />

      <Link to="./send" value="Submit" className="basic-button m60 w60">
        Recevoir un lien
      </Link>
    </div>
  );
}

export default PasswordForgotten;
