import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Light Cyber
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const footers = [
  {
    title: "Navigation",
    description: ["Acceuil", "Cours", "Quiz", "CTF"],
  },
  {
    title: "Nous Connaître",
    description: ["À propos", "Contact", "Devenir rédacteur", "FAQ"],
  },
];

export default function Footer() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          mb: 0,
          py: [1, 2],
          bottom:0,
          maxWidth:'none!important',
        }}
        style={{backgroundColor:'#F0F2F7'}}
        className='Footer'
      >
        <Grid container spacing={4} justifyContent={"flex-end"}>
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                {footer.description.map((item) => (
                  <li key={item} style={{ listStyleType: "none", margin: 0 }}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container style={{ textAlign: "center" }}>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
