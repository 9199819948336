import { colors } from "@mui/material";

function CourseCard() {
  return (
    <div style={{ flex: "0 0 28%", minWidth: "300px" }}>
      <div className="display-flex-center">
        <img
          src="https://picsum.photos/200/200"
          alt="image du cours"
          style={{
            borderRadius: "100px",
            background: "#ccc",
            padding: "2px",
          }}
        />
      </div>
      <div
        style={{
          background: "white",
          marginTop: "-70px",
          padding: "70px 20px 20px",
          borderRadius: "20px",
          textAlign: "center",
        }}
      >
        <h3>Titre</h3>
        <p>
          Urna nunc id cursus metus aliquam. At risus viverra adipiscing at in
          tellus integer feugiat scelerisque. Viverra suspendisse potenti nullam
          ac tortor. Sed felis eget velit aliquet. Blandit massa enim nec dui
          nunc mattis. Diam ut venenatis tellus in metus.
        </p>
        <a href="" style={{ color: "#15479E" }}>
          Voir plus
        </a>
      </div>
    </div>
  );
}

export default CourseCard;
