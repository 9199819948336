import TagNavBar from "../../components/TagNavBar";
import CourseCard from "./CourseCard";
import "./course.css";

function Course() {
  return (
    <>
      <div className="challenge-container">
        <TagNavBar></TagNavBar>
        <div className="challenges-container">
          {/* Description de la page */}
          <div className="description-container">
            <div className="text">
              <h2>MES COURS</h2>
              <p>
                Quam quisque id diam vel quam elementum. Duis ultricies lacus
                sed turpis tincidunt id aliquet risus feugiat. Donec enim diam
                vulputate ut pharetra.Tristique et egestas quis ipsum
                suspendisse ultrices. Lorem ipsum dolor sit amet consectetur.{" "}
              </p>
            </div>
            <img src="https://picsum.photos/200/80" alt="Image des cours" />
          </div>
          <div className="content-container">
            <CourseCard
              title="Les bases"
              description="Apprendre les bases"
            ></CourseCard>
            <CourseCard
              title="Les définitions"
              description="Quelques définitions utiles"
            ></CourseCard>
            <CourseCard
              title="Cour intermédiaire"
              description="Apprendre les connaissances utiles"
            ></CourseCard>
            <CourseCard
              title="Pour aller plus loin"
              description="Connaître"
            ></CourseCard>
            <CourseCard
              title="Pour les experts"
              description="Tout connaître sur le bout des doigts"
            ></CourseCard>
            <CourseCard
              title="Pour les experts"
              description="Tout connaître sur le bout des doigts"
            ></CourseCard>
          </div>
        </div>
      </div>
    </>
  );
}
export default Course;
