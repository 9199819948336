import "./courseDetail.css";

export default function CourseDetail() {
  return (
    <div className="courseDetail-container">
      <div className="courseDetails-container">
        <div className="content-container">
          <img src="https://picsum.photos/200/70" alt="Image des cours" />
        </div>
        <div className="content-container">
          <div className="text">
            <h2>Titre du cours</h2>
            <p>
              Tristique et egestas quis ipsum suspendisse ultrices. Lorem ipsum
              dolor sit amet consectetur. Quam quisque id diam vel quam
              elementum. Duis ultricies lacus sed turpis tincidunt id aliquet
              risus feugiat. Donec enim diam vulputate ut pharetra. Tristique et
              egestas quis ipsum suspendisse ultrices. Lorem ipsum dolor sit
              amet consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. Tristique et egestas quis
              ipsum suspendisse ultrices. Lorem ipsum dolor sit amet
              consectetur. Quam quisque id diam vel quam elementum. Duis
              ultricies lacus sed turpis tincidunt id aliquet risus feugiat.
              Donec enim diam vulputate ut pharetra. {" "}
            </p>

            <div className="buttons-connect">
              <div className="buttons-connect">
                <button role="button" className="buttonStyle">Voir les quizz</button>
              </div>
              <div className="buttons-connect">
                <button role="button" className="buttonStyle">Cours suivant</button>
              </div>
            </div>
          </div>

          <div className="buttons-disconnect">
                <button role="button" className="buttonStyle">S’inscrire ou se connecter pour voir plus</button>
          </div>

        </div>
      </div>
    </div>
  );
}
