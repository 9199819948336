function CourseCard(props) {
  return (
    <>
      <div className="course-card">
        <img src="https://picsum.photos/300/200" alt="Image du cours" />
        <div className="informations">
          <p>Titre : {props.title}</p>
          <p>Description : {props.description}</p>
        </div>
        <button>Accéder au cours</button>
      </div>
    </>
  );
}

export default CourseCard;
