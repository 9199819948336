// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-s1 {
  column-gap: 100px;
  display: flex;
  align-items: center;
}
.home-section {
  width: 100%;
  margin-top: 40px;
}
.home-description {
  padding: 100px;
  width: 40%;
}
.home-description p {
  text-align: left;
}
.home-description-img {
  width: 100%;
}
.home-description-img img {
  height: 800px;
  width: 800px;
}
.section-title {
  width: 100%;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 2em;
  color: #15479e;
}
.home-more {
  width: 30%;
  background-color: #15479e;
  height: 40px;
  border-radius: 40px;
  margin-top: 20px;
  color: white;
}
.course-section {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.course-section h2 {
  text-align: center;
}
.flag-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #15479e;
  color: white;
  padding-bottom: 100px;
}
.shadow-inset {
  box-shadow: 0px 15px 10px rgb(0, 0, 0, 0.4) inset,
    0px 5px 10px rgb(0, 0, 0, 0.3) inset, 0px -15px 10px rgb(0, 0, 0, 0.4) inset,
    0px -5px 10px rgb(0, 0, 0, 0.3) inset;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;AACA;EACE,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE;;yCAEuC;AACzC","sourcesContent":[".home-s1 {\n  column-gap: 100px;\n  display: flex;\n  align-items: center;\n}\n.home-section {\n  width: 100%;\n  margin-top: 40px;\n}\n.home-description {\n  padding: 100px;\n  width: 40%;\n}\n.home-description p {\n  text-align: left;\n}\n.home-description-img {\n  width: 100%;\n}\n.home-description-img img {\n  height: 800px;\n  width: 800px;\n}\n.section-title {\n  width: 100%;\n  margin-bottom: 50px;\n  font-weight: 700;\n  font-size: 2em;\n  color: #15479e;\n}\n.home-more {\n  width: 30%;\n  background-color: #15479e;\n  height: 40px;\n  border-radius: 40px;\n  margin-top: 20px;\n  color: white;\n}\n.course-section {\n  margin-top: 100px;\n  margin-bottom: 100px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.course-section h2 {\n  text-align: center;\n}\n.flag-section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #15479e;\n  color: white;\n  padding-bottom: 100px;\n}\n.shadow-inset {\n  box-shadow: 0px 15px 10px rgb(0, 0, 0, 0.4) inset,\n    0px 5px 10px rgb(0, 0, 0, 0.3) inset, 0px -15px 10px rgb(0, 0, 0, 0.4) inset,\n    0px -5px 10px rgb(0, 0, 0, 0.3) inset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
