import { Typography } from "@mui/material";
import ProfileNavBar from "../../components/ProfileNavBar";
import React, { useState } from "react";
import "./redactor.css";

export default function Redactor() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    cv: null,
    coverLetter: null,
    additionalFile: null,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* <div className="redactor-container">
        <TagNavBar></TagNavBar>
        <Typography
          variant="h2"
          sx={{ textAlign: "center", mb: "10px", mt: "30px" }}
        >
          Devenir Rédacteur
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "center", mr: "30px", ml: "30px" }}
        >
          Lorem ipsum dolor sit amet consectetur. Vitae habitant sagittis
          pellentesque elit Lorem ipsum dolor sit amet consectetur. Vitae
          habitant sagittis pellentesque el Lorem ipsum dolor sit amet
          consectetur. Vitae habitant sagittis pellentesque el Lorem ipsum dolor
          sit amet consectetur. Vitae habitant sagittis pellentesque el Lorem
          ipsum dolor sit amet consectetur. Vitae habitant sagittis pellentesque
          el nibh eget fames ac. Lorem ipsum dolor sit amet consectetur. Lorem
          ipsum dolor sit amet consectetur.
        </Typography>

        <form onSubmit={handleSubmit} className="redactor-form">
          <div className="row1">
            <div className="form-group">
              <label htmlFor="firstName">Prénom*</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Nom*</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="info">
            <div className="form-group">
              <label htmlFor="email">Adresse mail*</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Téléphone *</label>
              <input
                type="tel"
                name="phone"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="files">
            <div className="form-group">
              <label htmlFor="cv">
                Ajouter mon CV (certifications/références)
              </label>
              <input
                type="file"
                name="cv"
                id="cv"
                onChange={handleFileChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="coverLetter">
                Ajouter ma lettre de motivation
              </label>
              <input
                type="file"
                name="coverLetter"
                id="coverLetter"
                onChange={handleFileChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="additionalFile">
                Ajouter un fichier supplémentaire
              </label>
              <input
                type="file"
                name="additionalFile"
                id="additionalFile"
                onChange={handleFileChange}
              />
            </div>
          </div>

          <button type="submit" className="submit-button">
            Envoyer ma demande
          </button>
        </form>
      </div> */}
      <div>
        <ProfileNavBar></ProfileNavBar>
      </div>
    </>
  );
}
