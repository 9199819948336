// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth {
  width: 20%;
}

.auth-frame {
  margin: auto;
  margin-top: 30px;
  border-radius: 8px;
  background-color: white;
  width: 70vw;
  height: 90vh;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.auth-left {
  padding: 30px;
  width: 40%;
  overflow-y: auto;
  overflow-x: hidden;
}

.auth-left > p {
  font-size: 0.8em;
}

.auth-left > h2 {
  margin: 0px;
}

.auth-right {
  width: 60%;
  /* background-color: #606060; */
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

form > .auth-input {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.8px;
}

form > .auth-input > label {
  font-size: 18em;
}

p {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/auth.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8EAA8E;AAChF;;AAEA;EACE,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,+BAA+B;EAC/B,+BAA+B;EAC/B,4BAA4B;EAC5B,+BAA+B;EAC/B,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".auth {\n  width: 20%;\n}\n\n.auth-frame {\n  margin: auto;\n  margin-top: 30px;\n  border-radius: 8px;\n  background-color: white;\n  width: 70vw;\n  height: 90vh;\n  display: flex;\n  flex-direction: row;\n  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\n}\n\n.auth-left {\n  padding: 30px;\n  width: 40%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.auth-left > p {\n  font-size: 0.8em;\n}\n\n.auth-left > h2 {\n  margin: 0px;\n}\n\n.auth-right {\n  width: 60%;\n  /* background-color: #606060; */\n  border-bottom-left-radius: 30px;\n  border-top-left-radius: 30px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n}\n\nform > .auth-input {\n  margin-top: 5px;\n  margin-bottom: 5px;\n  font-size: 0.8px;\n}\n\nform > .auth-input > label {\n  font-size: 18em;\n}\n\np {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
