import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = (component) => {
  const loggedUser = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.loading.value);
  if (isLoading) {
    return <p>Loading</p>;
  }
  return loggedUser ? component : <Navigate to="/signin" />;
};

export default PrivateRoute;
