import gif from "../asset/images/404.gif";
import "../css/error.css";

function ErrorRoute() {
  return (
    <>
      <div className="error-main">
        <img src={gif} alt="404" className="error" />
      </div>
      <span>La page que vous rechercher n'existe pas.</span>
    </>
  );
}

export default ErrorRoute;
