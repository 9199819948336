// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
* CHALLENGE CARD 
**/

.course-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f8fc;
  border-radius: 10px;
  padding: 15px;
  width: 28%;
}

.course-card .informations {
  margin-top: 20px;
}

.course-card img {
  border-radius: 10px;
  width: 90%;
}

.course-card p {
  width: 90%;
  text-align: left;
  margin: 0px;
}

.course-card button {
  margin-top: 10px;
  width: 70%;
  border: none;
  border-radius: 10px;
  background-color: #b6d8f2;
  height: 40px;
}

.course-card button:hover {
  background-color: rgb(156, 189, 214);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Course/course.css"],"names":[],"mappings":"AAAA;;EAEE;;AAEF;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["/**\n* CHALLENGE CARD \n**/\n\n.course-card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #f6f8fc;\n  border-radius: 10px;\n  padding: 15px;\n  width: 28%;\n}\n\n.course-card .informations {\n  margin-top: 20px;\n}\n\n.course-card img {\n  border-radius: 10px;\n  width: 90%;\n}\n\n.course-card p {\n  width: 90%;\n  text-align: left;\n  margin: 0px;\n}\n\n.course-card button {\n  margin-top: 10px;\n  width: 70%;\n  border: none;\n  border-radius: 10px;\n  background-color: #b6d8f2;\n  height: 40px;\n}\n\n.course-card button:hover {\n  background-color: rgb(156, 189, 214);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
