// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: rgb(32, 32, 32);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.logo {
  height: 100px;
}

.link {
  text-decoration: none;
  color: white;
  padding: 15px;
}

.link:hover {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: rgb(249, 206, 255);
  /* background-color: rgb(249, 206, 255); */
}

.link:active {
  border-bottom: rgb(249, 206, 255);
}

.separator {
  height: 100px;
  width: vw;
  background: linear-gradient(180deg, rgb(236, 190, 247), white);
}

.left {
  position: absolute;
  left: 50px;
}

.right {
  position: absolute;
  right: 50px;
}

.logout {
  border: none;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/header.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,+CAA+C;AACjD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,wBAAwB;EACxB,uCAAuC;EACvC,0CAA0C;AAC5C;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,8DAA8D;AAChE;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB","sourcesContent":[".Header {\n  position: absolute;\n  display: flex;\n  top: 0;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 60px;\n  background-color: rgb(32, 32, 32);\n  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);\n}\n\n.logo {\n  height: 100px;\n}\n\n.link {\n  text-decoration: none;\n  color: white;\n  padding: 15px;\n}\n\n.link:hover {\n  border-bottom-style: solid;\n  border-bottom-width: 3px;\n  border-bottom-color: rgb(249, 206, 255);\n  /* background-color: rgb(249, 206, 255); */\n}\n\n.link:active {\n  border-bottom: rgb(249, 206, 255);\n}\n\n.separator {\n  height: 100px;\n  width: vw;\n  background: linear-gradient(180deg, rgb(236, 190, 247), white);\n}\n\n.left {\n  position: absolute;\n  left: 50px;\n}\n\n.right {\n  position: absolute;\n  right: 50px;\n}\n\n.logout {\n  border: none;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
