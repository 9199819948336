// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Barre de navigation */
.navbar {
  width: 15%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 10fr;
  align-items: center;
  /* justify-content: center; */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  color: white;
}

.navbar h2 {
  font-size: 1.2em;
}

.nav-logo {
  display: flex;
  align-items: center;
  color: black;
  padding: 15px;
}

.nav-logo img {
  height: 80px;
}

.nav-tabs {
  height: 85%;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  background-color: #15479e;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.nav-tabs::-webkit-scrollbar {
  display: none;
}

.nav-tabs h2 {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 50px;
  padding: 20px;
}

.nav-tab {
  padding: 10px 20px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.nav-tab:hover {
  background-color: #1f57b8;
}
`, "",{"version":3,"sources":["webpack://./src/css/tag-nav-bar.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,UAAU;EACV,aAAa;EACb,0BAA0B;EAC1B,4BAA4B;EAC5B,mBAAmB;EACnB,6BAA6B;EAC7B,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,UAAU;EACV,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;EACzB,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Barre de navigation */\n.navbar {\n  width: 15%;\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: 1fr 10fr;\n  align-items: center;\n  /* justify-content: center; */\n  position: sticky;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  height: 100vh;\n  color: white;\n}\n\n.navbar h2 {\n  font-size: 1.2em;\n}\n\n.nav-logo {\n  display: flex;\n  align-items: center;\n  color: black;\n  padding: 15px;\n}\n\n.nav-logo img {\n  height: 80px;\n}\n\n.nav-tabs {\n  height: 85%;\n  width: 100%;\n  overflow-y: auto;\n  scrollbar-width: none;\n  background-color: #15479e;\n  border-top-right-radius: 40px;\n  border-bottom-right-radius: 40px;\n}\n\n.nav-tabs::-webkit-scrollbar {\n  display: none;\n}\n\n.nav-tabs h2 {\n  text-align: center;\n  font-size: 1.2em;\n  margin-bottom: 50px;\n  padding: 20px;\n}\n\n.nav-tab {\n  padding: 10px 20px 10px;\n  cursor: pointer;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  column-gap: 20px;\n}\n\n.nav-tab:hover {\n  background-color: #1f57b8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
