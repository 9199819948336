import logo from "../../asset/images/logo_LC.png";
import rightImg from "../../asset/images/auth-img.png";
import { TextField, Snackbar } from "@mui/material";
import { login as setLoggedUser } from "../../store/reducers/auth";
import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";

function Signin() {
  const url = process.env.REACT_APP_URL + "/auth/login";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openGoogle, setOpenGoogle] = useState(false);

  const dispatch = useDispatch();

  const connect = async () => {
    try {
      let response = await axios.post(url, {
        email,
        password,
      });
      let token = response.data["accessToken"];
      dispatch(setLoggedUser(response.data));
      window.localStorage.setItem("token", token);
      navigate("/");
    } catch (error) {
      console.error(error.message);
      setOpen(true);
    }
  };

  const connectGoogle = () => {
    setOpenGoogle(true);
  };

  const handleCloseGoogle = () => {
    setOpenGoogle(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        style={{
          //   backgroundImage: `url(
          //   "https://media.giphy.com/media/sRFEa8lbeC7zbcIZZR/giphy.gif"
          // )`,
          background: "#CCDDEE",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          filter: "blur(3px)",
          transform: "scale(1.1)",
        }}
      ></div>
      <div
        className="auth-frame"
        style={{ zIndex: 1000, position: "relative" }}
      >
        <div className="auth-left display-flex-center">
          <img
            src={logo}
            alt="Logo de l'appication"
            width="200px"
            height="100px"
            className="img-black"
          />
          <h2>Connectez-vous</h2>
          <form action="" className="display-flex-center">
            <TextField
              type="email"
              className="auth-input"
              id="email"
              label="Adresse mail"
              variant="standard"
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              type="password"
              className="auth-input"
              id="password"
              label="Mot de passe"
              variant="standard"
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <button
              type="button"
              value="Submit"
              onClick={connect}
              className="basic-button m30"
              style={{ backgroundColor: "#15479E", color: "white" }}
            >
              Connexion
            </button>
            <button
              type="button"
              value="Submit"
              onClick={connectGoogle}
              className="basic-button-white m30"
            >
              Connexion avec Google
            </button>
          </form>
          <p>
            <Link
              className="a-basic m30"
              style={{ fontStyle: "italic" }}
              to="/forgotten"
            >
              Mot de passe oublié ?
            </Link>
          </p>
          <p>
            Vous n'avez pas de compte ?{" "}
            <Link className="a-basic m30" to="/register">
              Inscrivez-vous
            </Link>
          </p>
        </div>
        <div
          className="auth-right"
          style={{
            backgroundImage: `url(${rightImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={"Identifiant ou mot de passe incorrect."}
      />
    </div>
    // <div className="main">
    //   <h1 className="title">Connectez vous</h1>
    //   <form className="form">
    //     <div className="input">
    //       <TextField
    //         type="email"
    //         className="input"
    //         id="email"
    //         label="Email"
    //         variant="outlined"
    //         onChange={(event) => {
    //           setEmail(event.target.value);
    //         }}
    //         required
    //       />
    //     </div>
    //     <div className="input">
    //       <TextField
    //         type="password"
    //         className="input"
    //         id="password"
    //         label="Mot de Passe"
    //         variant="outlined"
    //         onChange={(event) => {
    //           setPassword(event.target.value);
    //         }}
    //         required
    //       />
    //     </div>
    //     <button
    //       type="button"
    //       value="Submit"
    //       onClick={connect}
    //       className="basic-button"
    //     >
    //       Se connecter
    //     </button>
    //   </form>

    //   <div>
    //     <Link to="/register">Pas encore inscrit ?</Link>
    //   </div>
    // </div>
  );
}

export default Signin;
