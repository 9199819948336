import * as React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function QuestionCard(props) {
  const [open, setOpen] = React.useState(false);

  const styles = {
    main: {
      display: "flex",
      alignItems: "center",
      columnGap: "20px",
      backgroundColor: "#15479E",
      borderRadius: "20px",
      marginBottom: "20px",
      color: "white",
      paddingLeft: "20px",
    },
    anwser: {
      paddingLeft: "30px",
    },
    none: {
      display: "none",
    },
    open: {
      rotate: "90deg",
      transition: "0.2s",
    },
    close: {
      transition: "0.2s",
    },
  };

  const showAnwser = () => {
    setOpen(!open);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <div style={styles.main} onClick={showAnwser}>
        <KeyboardArrowRightIcon style={open ? styles.open : styles.close} />
        <h3 style={styles.title}>{props.title}</h3>
      </div>
      <div style={open ? styles.anwser : styles.none}>{props.anwser}</div>
    </div>
  );
}

export default QuestionCard;
