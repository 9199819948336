function ChallengeCard(props) {
  return (
    <>
      <div className={"challenge-card " + (props.ctf ? "ctf" : "")}>
        <img src="https://picsum.photos/300/200" alt="Image du challenge" />
        <div className="informations">
          <p>Titre : {props.title}</p>
          <p>Timer : {props.timer}</p>
        </div>
        <button>Accéder au cours</button>
      </div>
    </>
  );
}

export default ChallengeCard;
