import ChallengeCard from "./ChallengeCard";
import React from "react";
import Switch from "@mui/material/Switch";
import "./challenge.css";
import TagNavBar from "../../components/TagNavBar";

function Challenge() {
  const [ctf, setCtf] = React.useState(false);

  const update = () => {
    setCtf(!ctf);
  };

  return (
    <div className="challenge-container">
      <TagNavBar></TagNavBar>
      <div className="challenges-container">
        {/* Description de la page */}
        <div className="description-container">
          <div className="text">
            <h2>MES CHALLENGES</h2>
            <p>
              Tristique et egestas quis ipsum suspendisse ultrices. Lorem ipsum
              dolor sit amet consectetur. Quam quisque id diam vel quam
              elementum. Duis ultricies lacus sed turpis tincidunt id aliquet
              risus feugiat. Donec enim diam vulputate ut pharetra.{" "}
            </p>
          </div>
          <img src="https://picsum.photos/200/80" alt="Image des cours" />
        </div>
        <Switch label="Swtich to  " defaultChecked onChange={update} />
        <p>Switch to {ctf ? "Quizz" : "CTF"}</p>
        <div className="content-container">
          <ChallengeCard title="Les bases" timer="" ctf={ctf}></ChallengeCard>
          <ChallengeCard
            title="Les définitions"
            timer=""
            ctf={ctf}
          ></ChallengeCard>
          <ChallengeCard
            title="Cour intermédiaire"
            timer=""
            ctf={ctf}
          ></ChallengeCard>
          <ChallengeCard
            title="Pour aller plus loin"
            timer=""
            ctf={ctf}
          ></ChallengeCard>
          <ChallengeCard
            title="Pour les experts"
            timer=""
            ctf={ctf}
          ></ChallengeCard>
          <ChallengeCard
            title="Pour les experts"
            timer=""
            ctf={ctf}
          ></ChallengeCard>
        </div>
      </div>
    </div>
  );
}

export default Challenge;
