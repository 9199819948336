import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { login as setLoggedUser } from "./store/reducers/auth";
import { setLoading } from "./store/reducers/loading";

// const getUser = async () => {
//   let token = window.localStorage.getItem("token");
//   console.log("TOKEN : ", token);
//   const user = await axios.get("https://127.0.0.1:8000/api/users/1/info", {
//     headers: { Authorization: `Bearer ${token}` },
//   });
//   store.dispatch(setLoggedUser(user.data));
// };

// const getAllUsers = async () => {
//   const users = await axios.get("https://127.0.0.1:8000/api/users");
//   store.dispatch(setUsers(users.data["hydra:member"]));
// };

// Promise.all([getUser(), getAllUsers()]).finally(() =>
//   store.dispatch(setLoading(false))
// );

const getUser = async () => {
  let token = window.localStorage.getItem("token");
  console.log("TOKEN : ", token);
  if (token) {
    const user = await axios.get(
      "http://localhost:8000/user/65e8502938ce4ebe790db553",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    store.dispatch(setLoggedUser(user.data));
  }
};

// Promise.all([getUser()]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
