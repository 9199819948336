import logo from "../../asset/images/logo_LC.png";
import rightImg from "../../asset/images/auth-img.png";
import { TextField } from "@mui/material";
import { Snackbar } from "@mui/material";
import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

function Register() {
  const url = process.env.REACT_APP_URL + "/auth/register";
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const regexEmail = "^[A-z0-9._%+-]+@[A-z0-9.-]+.[A-z]{2,4}$";
  const regexUsername = "^[A-z0-9._%+-]+$";

  const [errorMessage, setErrorMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const [openGoogle, setOpenGoogle] = useState(false);

  const connectGoogle = () => {
    setOpenGoogle(true);
  };

  const handleCloseGoogle = () => {
    setOpenGoogle(false);
  };

  const createUser = async () => {
    if (!email.match(regexEmail)) {
      setErrorMessage("Email invalide.");
      setOpen(true);
      return;
    }
    if (!username.match(regexUsername)) {
      setErrorMessage("Username invalide.");
      setOpen(true);
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Le mot de passe doit contenir au moins 8 caractères.");
      setOpen(true);
      return;
    }
    if (password != confirmedPassword) {
      setErrorMessage("Les mots de passes doivent être identiques.");
      setOpen(true);
      return;
    }
    try {
      await axios.post(url, {
        firstname,
        lastname,
        email,
        username,
        password,
        role: "65a7de88e0140135ca14f359",
      });
      navigate("/signin");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        style={{
          //   backgroundImage: `url(
          //   "https://media.giphy.com/media/sRFEa8lbeC7zbcIZZR/giphy.gif"
          // )`,
          background: "#CCDDEE",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          filter: "blur(3px)",
          transform: "scale(1.1)",
        }}
      ></div>
      <div className="auth-frame" style={{ position: "relative" }}>
        <div className="auth-left display-flex-center">
          <img
            src={logo}
            alt="Logo de l'appication"
            width="200px"
            height="100px"
            className="img-black"
          />
          <h2>Créez votre compte</h2>
          <form action="" className="display-flex-center">
            <TextField
              type="text"
              className="auth-input"
              id="firstname"
              label="Prénom"
              variant="standard"
              required
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
            />
            <TextField
              type="text"
              className="auth-input"
              id="lastname"
              label="Nom"
              variant="standard"
              required
              onChange={(event) => {
                setLastname(event.target.value);
              }}
            />
            <TextField
              type="text"
              className="auth-input"
              id="username"
              label="Nom d'utilisateur"
              variant="standard"
              required
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />
            <TextField
              type="email"
              className="auth-input"
              id="email"
              label="Adresse mail"
              variant="standard"
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              type="password"
              className="auth-input"
              id="password"
              label="Mot de passe"
              variant="standard"
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <TextField
              type="password"
              className="auth-input"
              id="password-v"
              label="Confirmez le mot de passe"
              variant="standard"
              required
              onChange={(event) => {
                setConfirmedPassword(event.target.value);
              }}
            />
            <button
              type="button"
              value="Submit"
              className="basic-button m30"
              onClick={createUser}
            >
              Inscription
            </button>
          </form>
          <p>
            Vous avez déjà un compte ?{" "}
            <Link className="a-basic" to="/signin">
              Connectez-vous
            </Link>
            <br />
            <br />
            ou
          </p>
          <button
            type="button"
            className="basic-button-white w60"
            onClick={connectGoogle}
          >
            Connexion avec Google
          </button>
        </div>
        <div
          className="auth-right"
          style={{
            backgroundImage: `url(${rightImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <Snackbar
        open={openGoogle}
        autoHideDuration={2000}
        onClose={handleCloseGoogle}
        message={"Connexion avec google indisponible pour le moment."}
      />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={errorMessage}
      />
    </div>
    /* <div className="main">
      <h1 className="title">S'INSCRIRE</h1>
      <form className="form">
        <div className="input">
          <TextField
            className="input"
            id="username"
            label="Username"
            variant="outlined"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </div>
        <div className="input">
          <TextField
            type="email"
            className="input"
            id="email"
            label="Email"
            variant="outlined"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div className="input">
          <TextField
            type="password"
            id="password"
            label="Mot de Passe"
            variant="outlined"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <button
          type="button"
          value="Submit"
          className="basic-button"
          onClick={createUser}
        >
          S'inscrire
        </button>
      </form>

      <div>
        <Link to="/signin">Déjà inscrit ?</Link>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={errorMessage}
      />
    </div>*/
  );
}

export default Register;
