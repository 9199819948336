import * as React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/reducers/auth";

function Profile() {
  const token = window.localStorage.getItem("token");
  const url = process.env.REACT_APP_URL + "/api/users/1/info";

  const [user, setUser] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = async () => {
    try {
      await deleteAccount();

      window.localStorage.clear();
      dispatch(logout());
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [token]);

  const deleteAccount = async () => {
    await axios.delete(`${process.env.REACT_APP_URL}/api/users/${user.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  return <div className="profile"></div>;
}

export default Profile;
