import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    usersLogged: [],
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUsersLogged: (state, action) => {
      state.usersLogged = action.payload;
    },
  },
});

export const { setUsers, setUsersLogged } = userSlice.actions;

export default userSlice.reducer;
