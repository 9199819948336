import CourseCard from "./components/CourseCard";
import QuestionCard from "./components/QuestionCard";
import DescriptionImage from "../../asset/images/home-description.png";
import Forme from "../../asset/images/forme.png";
import logo from "../../asset/images/logoB.png";
import "./home.css";

function Home() {
  return (
    <>
      <div className="nav-logo">
        <img src={logo} alt="Logo de lightcyber" />
        <p>Lightcyber</p>
      </div>
      <div className="display-flex-center">
        <section className="home-section">
          <div className="home-description">
            <h2 className="section-title">LIGHTCYBER</h2>
            <p>
              Explorez notre plateforme unique d'apprentissage où l'éducation
              devient un jeu captivant. Transformez votre parcours
              d'apprentissage en une aventure passionnante, où chaque défi est
              une opportunité pour acquérir les compétences nécessaires pour
              exceller dans le monde dynamique de la technologie.
            </p>
            <p>Sécurisez votre avenir numérique tout en vous amusant.</p>
            <button className="home-more">Voir plus</button>
          </div>
        </section>
        <section className="course-section">
          <h2 className="section-title">Nos cours mis en valeur</h2>
          <div
            className="display-flex"
            style={{
              columnGap: "30px",
              rowGap: "50px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <CourseCard></CourseCard>
            <CourseCard></CourseCard>
            <CourseCard></CourseCard>
          </div>
          <button className="home-more">+</button>
        </section>
        <section className="flag-section">
          <div
            className="display-flex"
            style={{ columnGap: "80px", paddingTop: "100px" }}
          >
            <img src="https://picsum.photos/400/300" alt="" />
            <div>
              <h2 className="section-title" style={{ color: "white" }}>
                Capture the flag
              </h2>
              <p>
                Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
              </p>
              <button
                className="home-more"
                style={{ backgroundColor: "#e7ebf4", color: "#15479E" }}
              >
                Voir plus
              </button>
            </div>
          </div>
          <div
            className="display-flex"
            style={{ columnGap: "80px", paddingTop: "100px" }}
          >
            <div>
              <h2 className="section-title" style={{ color: "white" }}>
                Capture the flag
              </h2>
              <p>
                Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
              </p>
              <button
                className="home-more"
                style={{ backgroundColor: "#e7ebf4", color: "#15479E" }}
              >
                Voir plus
              </button>
            </div>
            <img src="https://picsum.photos/400/300" alt="" />
          </div>
        </section>
        <section
          className="home-section display-flex-center"
          style={{
            background: "#eee",
            textAlign: "center",
            paddingTop: "30px",
            marginBottom: "100px",
          }}
        >
          <h2 className="section-title">FAQ</h2>

          <div style={{ width: "60%" }}>
            <QuestionCard
              title="Quels sujets spécifiques couvre la plateforme ?"
              anwser="Cybersécurité - Infrastructure - Dev Web"
            ></QuestionCard>
            <QuestionCard
              title="Comment puis-je m'inscrire et commencer à apprendre ?"
              anwser="Créé simplement un compte et vous aurez accès aux cours en ligne !"
            ></QuestionCard>
            <QuestionCard
              title="Quels sont les prérequis pour suivre les cours sur la plateforme ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Les cours sont-ils adaptés aux débutants ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Y a-t-il des certifications disponibles à la fin des cours ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Comment fonctionnent les cours interactifs et les jeux d'apprentissage ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Comment la plateforme assure-t-elle la sécurité des données des utilisateurs ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Les cours sont-ils accessibles hors ligne ?"
              anwser=""
            ></QuestionCard>
            <QuestionCard
              title="Comment puis-je suivre mes progrès sur la plateforme ?"
              anwser=""
            ></QuestionCard>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
