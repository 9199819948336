import logo from "../../asset/images/logo_LC.png";
import rightImg from "../../asset/images/auth-img.png";
import { Link } from "react-router-dom";
import "../../App.css";
import "./auth.css";

function Auth() {
  return (
    <div className="auth-frame">
      <div className="auth-left display-flex-center">
        <img
          src={logo}
          alt="Logo de l'appication"
          width="200px"
          height="100px"
          className="img-black"
        />
        <h1 className="appName">LightCyber</h1>
        <Link
          to="/signin"
          className="basic-button w60 m30"
          style={{ backgroundColor: "#15479E" }}
        >
          Connexion
        </Link>
        <Link to="/register" className="basic-button w60 m30">
          S'inscrire
        </Link>
      </div>
      <div
        className="auth-right"
        style={{
          backgroundImage: `url(${rightImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
    </div>
  );
}

export default Auth;
