import { Outlet, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Profile from "./pages/Profile/Profile";
import Register from "./pages/Auth/Register";
import Signin from "./pages/Auth/Signin";
import ErrorRoute from "./pages/Error";
import GuestRoute from "./routes/GuestRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Auth from "./pages/Auth/Auth";
import Home from "./pages/Home/Home";
import PasswordForgotten from "./pages/Auth/PasswordForgotten";
import PasswordForgottenSend from "./pages/Auth/PasswordForgottenSend";
import Challenge from "./pages/Challenge/Challenge";
import Redactor from "./pages/Redactor/Redactor";
import ShortHeader from "./components/ShortHeader";
import Course from "./pages/Course/Course";
import CourseDetail from "./pages/CourseDetail/CourseDetail";

const Layout = () => {
  const location = useLocation(); // Assurez-vous d'importer 'useLocation' depuis 'react-router-dom'
  const hideHeaderRoutes = [
    "/auth",
    "/signin",
    "/register",
    "/forgotten",
    "/challenge",
    "/course",
    "/home",
    "/redactor",
    "/",
  ]; // Ajoutez ici les chemins où vous ne voulez pas afficher le Header
  const shortHeaderRoutes = [
    "/challenge",
    "/course",
    "/home",
    "/",
    "/redactor",
  ]; // Ajoutez ici les chemins où vous ne voulez pas afficher le Header
  const hideFooterRoutes = ["/auth", "/signin", "/register", "/forgotten"]; // Ajoutez ici les chemins où vous ne voulez pas afficher le footer
  const shouldDisplayHeader = !hideHeaderRoutes.includes(location.pathname);
  const shouldDisplayShortHeader = shortHeaderRoutes.includes(
    location.pathname
  );
  const shouldDisplayFooter = !hideFooterRoutes.includes(location.pathname);

  return (
    <>
      {shouldDisplayHeader && <Header />}
      {shouldDisplayShortHeader && <ShortHeader />}
      <Outlet />
      {shouldDisplayFooter && <Footer />}
    </>
  );
};

const routes = () => [
  {
    element: <Layout />,
    children: [
      {
        path: "/auth",
        element: <Auth />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "user/profile",
        element: <>{PrivateRoute(<Profile />)}</>,
      },
      {
        path: "signin",
        element: <>{GuestRoute(<Signin />)}</>,
      },
      {
        path: "register",
        element: <>{GuestRoute(<Register />)}</>,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "course",
        element: <Course />,
      },
      {
        path: "challenge",
        element: <Challenge />,
      },
      {
        path: "courseDetail",
        element: <CourseDetail />,
      },
      {
        path: "forgotten",
        element: <>{GuestRoute(<PasswordForgotten />)}</>,
      },
      ,
      {
        path: "redactor",
        element: <>{GuestRoute(<Redactor />)}</>,
      },

      {
        path: "forgotten/send",
        element: <>{GuestRoute(<PasswordForgottenSend />)}</>,
      },
      {
        path: "*",
        element: <ErrorRoute />,
      },
    ],
  },
];

export default routes;
