import routes from "./routes";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Snackbar } from "@mui/material";
const ENDPOINT = "http://127.0.0.1:4001";

export default function App() {
  const loggedUser = useSelector((state) => state.auth.loggedUser);
  const routing = useRoutes(routes(loggedUser));

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleOpenSnackBar = () => setOpenSnackBar(true);
  const handleCloseSnackBar = () => setOpenSnackBar(false);

  return (
    <>
      {routing}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
        message={message}
      />
    </>
  );
}
