// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Kantumruy Pro", sans-serif;
}

.basic-button {
  padding: 10px 20px 10px;
  border-radius: 15px;
  background-color: #d9d9d9;
  text-decoration: none;
  color: black;
  font-weight: 700;
  width: 100%;
  text-align: center;
  border: none;
  /* box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3); */
}

.basic-button:hover {
  background-color: #dfdfdf;
}

.basic-button-white {
  padding: 10px 20px 10px;
  border-radius: 15px;
  background-color: white;
  width: 100%;
  border: solid 2px #d9d9d9;
}

.basic-button-white:hover {
  background-color: #fafafa;
}

.a-basic {
  color: black;
  font-weight: bolder;
  text-decoration: none;
}

.display-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-flex {
  display: flex;
  flex-direction: row;
  padding: 0px 100px 0px;
}

.img-black {
  filter: invert(100%);
}

.input-center > label {
  text-align: center;
  width: 100%;
}

.m30 {
  margin-top: 30px;
}

.m60 {
  margin-top: 60px;
}

.w60 {
  width: 60%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,+CAA+C;AACjD;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["* {\n  font-family: \"Kantumruy Pro\", sans-serif;\n}\n\n.basic-button {\n  padding: 10px 20px 10px;\n  border-radius: 15px;\n  background-color: #d9d9d9;\n  text-decoration: none;\n  color: black;\n  font-weight: 700;\n  width: 100%;\n  text-align: center;\n  border: none;\n  /* box-shadow: 3px 3px 3px rgb(0, 0, 0, 0.3); */\n}\n\n.basic-button:hover {\n  background-color: #dfdfdf;\n}\n\n.basic-button-white {\n  padding: 10px 20px 10px;\n  border-radius: 15px;\n  background-color: white;\n  width: 100%;\n  border: solid 2px #d9d9d9;\n}\n\n.basic-button-white:hover {\n  background-color: #fafafa;\n}\n\n.a-basic {\n  color: black;\n  font-weight: bolder;\n  text-decoration: none;\n}\n\n.display-flex-center {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.display-flex {\n  display: flex;\n  flex-direction: row;\n  padding: 0px 100px 0px;\n}\n\n.img-black {\n  filter: invert(100%);\n}\n\n.input-center > label {\n  text-align: center;\n  width: 100%;\n}\n\n.m30 {\n  margin-top: 30px;\n}\n\n.m60 {\n  margin-top: 60px;\n}\n\n.w60 {\n  width: 60%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
